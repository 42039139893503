<template>
	<router-view />
</template>

<style>
#app {
	min-height: 100%;
	position: relative;
	display: flex;
}
.ant-statistic-content {
	color: #586069 !important;
}
</style>
