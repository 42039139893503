<template>
	<module-title :title="group + host" back="1"></module-title>
	<div class="operations">
		<span class="picker"
			><a-date-picker
				v-model:value="timeRange.day"
				placeholder="查询日期"
				value-format="YYYY-MM-DD"
		/></span>
		<span class="picker">
			<a-time-picker
				v-model:value="timeRange.range.start"
				placeholder="开始时间"
				value-format="HH:mm:ss"
			/>
		</span>
		<span class="picker">
			<a-time-picker
				v-model:value="timeRange.range.end"
				placeholder="结束时间"
				value-format="HH:mm:ss"
			/>
		</span>
		<a-button type="primary" @click="searchLogCustom">检索日志</a-button>
	</div>
	<div class="overview">
		<span>
			<b class="green"
				>{{ searchResult.time.start }} 到 {{ searchResult.time.end }}</b
			></span
		>
		<span class="dec">
			<b>{{ searchResult.total }} 个结果</b>
		</span>
		<span class="dec">
			搜索: <b>{{ searchResult.requestCostTime }}</b>
		</span>
		<span class="dec">
			计算: <b>{{ searchResult.countCostTime }}</b>
		</span>
	</div>
	<div class="list">
		<a-table
			:columns="columns"
			:data-source="searchResult.hitsByZidCount"
			:row-key="(record) => record.host"
			:pagination="store.state.pagination"
			size="middle"
			:loading="searchLogLoading"
		>
			<template #action="{ record }">
				<router-link
					:to="{
						path: '/admin/log/log_by_host_zid',
						query: {
							host: host,
							group: group,
							zid: record.host,
							start: timeRange.day
								? `${timeRange.day} ${timeRange.range.start}`
								: '',
							end: timeRange.day
								? `${timeRange.day} ${timeRange.range.end}`
								: '',
						},
					}"
				>
					日志图表</router-link
				>
			</template>
		</a-table>
	</div>
</template>

<script>
import ModuleTitle from "../components/ModuleTitle";
import { log } from "@/utils/api";
import { onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";

export default {
	props: {
		host: String,
		group: String,
	},
	name: "LogByHost",
	components: { ModuleTitle },
	setup(props) {
		const columns = [
			{
				title: "主机/ZID",
				dataIndex: "host",
				sortOrder: "descend",
			},
			{
				title: "连接数",
				dataIndex: "hostConn",
			},
			{
				title: "入站流量(合计)",
				dataIndex: "hostIn",
			},
			{
				title: "出站流量(合计)",
				dataIndex: "hostOut",
			},
			{
				title: "分析",
				slots: { customRender: "action" },
			},
		];
		const store = useStore();
		const searchResult = reactive({
			time: {
				start: "",
				end: "",
			},
			total: 0,
			requestCostTime: "",
			countCostTime: "",
			hitsByZidCount: [],
		});
		const searchLogLoading = ref(false);
		async function searchLog(host) {
			searchLogLoading.value = true;
			await log.searchLog(host).then((res) => {
				if (res.data.code === 0) {
					searchResult.time.start = res.data.data.time.start;
					searchResult.time.end = res.data.data.time.end;
					searchResult.total = res.data.data.total;
					searchResult.requestCostTime = res.data.data.requestCostTime;
					searchResult.countCostTime = res.data.data.countCostTime;
					searchResult.hitsByZidCount = res.data.data.hitsByZidCount;
				}
			});
			searchLogLoading.value = false;
		}
		// 时间选择
		const timeRange = reactive({
			day: "",
			range: {
				start: "",
				end: "",
			},
		});
		async function searchLogCustom() {
			searchLogLoading.value = true;
			await log
				.searchLog(
					props.host,
					timeRange.day + " " + timeRange.range.start,
					timeRange.day + " " + timeRange.range.end
				)
				.then((res) => {
					if (res.data.code === 0) {
						searchResult.time.start = res.data.data.time.start;
						searchResult.time.end = res.data.data.time.end;
						searchResult.total = res.data.data.total;
						searchResult.requestCostTime = res.data.data.requestCostTime;
						searchResult.countCostTime = res.data.data.countCostTime;
						searchResult.hitsByZidCount = res.data.data.hitsByZidCount;
					}
				});
			searchLogLoading.value = false;
		}
		onMounted(() => {
			searchLog(props.host).then();
		});
		return {
			searchResult,
			columns,
			searchLogLoading,
			store,
			timeRange,
			searchLogCustom,
		};
	},
};
</script>

<style scoped>
.overview {
	color: #586069;
	margin: 20px;
}
.overview b {
	color: #586069;
	margin-left: 5px;
}
.overview span {
	margin-right: 20px;
}
.picker {
	margin-right: 10px;
}
.overview .green {
	color: #2ea44f;
}
</style>
