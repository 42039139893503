/* eslint-disable prettier/prettier */
import axios from "axios";
import qs from "qs";
import { message } from "ant-design-vue";
import store from "../store";
import router from "../router";

function errorMessageToString(error) {
	const type = typeof error.response.data.statusMessage;
	const messageType = typeof error.response.data.statusMessage.message;

	switch (type) {
		case "string":
			return error.response.data.statusMessage;
		case "object":
			if (messageType === "object") {
				return error.response.data.statusMessage.message[0];
			} else {
				return error.response.data.statusMessage.message;
			}

		default:
			return "Something went wrong";
	}
}
export const base = {
	url: "https://z.51tcp.com",
	// url: "http://localhost:3693",
};

const service = axios.create({
	timeout: 600000,
	headers: {
		"Content-Type": "application/x-www-form-urlencoded",
		"Access-Control-Allow-Credentials": "true",
	},
	transformRequest: (data) => qs.stringify(data),
	baseURL: base.url,
});

service.interceptors.request.use((config) => {
	if (store.state.token) {
		config.headers.Authorization = `Bearer ${store.state.token}`;
		return config;
	}
	return config;
});

service.interceptors.response.use(
	(res) => {
		return Promise.resolve(res);
	},
	async (error) => {
		let messageInfo;
		if (error.response) {
			switch (error.response.status) {
				case 401:
					// 未认证或登录失效
					// 跳转登录
					// store 提交退出，清除
					message.warning("登录失效,请重新登录.");
					store.commit("quit");
					await router.push("/");
					break;
				default:
					break;
			}
			messageInfo = errorMessageToString(error);
		} else if (error.request) {
			messageInfo = "请求超时,请检查网络或刷新重试.";
		} else {
			// Something happened in setting up the request that triggered an Error
			messageInfo = error.message;
		}
		message.error(messageInfo); // 弹出错误
		return Promise.reject(messageInfo);
	}
);

export default service;
