<template>
	<module-title title="日志分析"></module-title>
	<div class="list">
		<a-table
			:columns="columns"
			:data-source="hostList"
			:row-key="(record) => record.host"
			:pagination="store.state.pagination"
			size="middle"
			:loading="hostListLoading"
		>
			<template #action="{ record }">
				<router-link
					:to="{
						path: '/admin/log/log_by_host',
						query: { host: record.host, group: record.group },
					}"
					>日志图表</router-link
				>
			</template>
		</a-table>
	</div>
</template>

<script>
import ModuleTitle from "@/components/ModuleTitle";
import { log } from "@/utils/api";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
export default {
	name: "LogHost",
	components: { ModuleTitle },
	setup() {
		const store = useStore();
		const columns = [
			{
				title: "分组",
				dataIndex: "group",
				sortOrder: "descend",
			},
			{
				title: "主控IP",
				dataIndex: "host",
			},
			{
				title: "分析",
				slots: { customRender: "action" },
			},
		];
		const hostList = ref([]);
		const hostListLoading = ref(false);
		async function getHostList() {
			hostListLoading.value = true;
			await log
				.hostList()
				.then((res) => {
					if (res.data.code === 0) {
						hostList.value = res.data.data;
					} else {
						message.error(res.data.message);
					}
				})
				.catch();
			hostListLoading.value = false;
		}
		onMounted(() => {
			getHostList();
		});
		return {
			columns,
			hostList,
			store,
			hostListLoading,
		};
	},
};
</script>

<style scoped></style>
