import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./base.css";

import {
	Button,
	Input,
	Checkbox,
	Table,
	Steps,
	Form,
	Select,
	InputNumber,
	Statistic,
	Descriptions,
	Popconfirm,
	// Upload,
	Card,
	Row,
	Col,
	Spin,
	Dropdown,
	Menu,
	Alert,
	Result,
	Modal,
	DatePicker,
	TimePicker,
	Drawer,
	AutoComplete,
	Pagination,
	Radio,
	Tag,
	Space,
	Upload,
} from "ant-design-vue";

createApp(App)
	.use(store)
	.use(router)
	.use(Button)
	.use(Input)
	.use(Checkbox)
	.use(Table)
	.use(Steps)
	.use(Form)
	.use(Select)
	.use(InputNumber)
	.use(Statistic)
	.use(Descriptions)
	.use(Popconfirm)
	.use(Pagination)
	.use(Tag)
	.use(Radio)
	.use(Upload)
	.use(Card)
	.use(Row)
	.use(Col)
	.use(Spin)
	.use(Dropdown)
	.use(Menu)
	.use(Alert)
	.use(Result)
	.use(Modal)
	.use(DatePicker)
	.use(TimePicker)
	.use(Drawer)
	.use(AutoComplete)
	.use(Space)
	.mount("#app");
