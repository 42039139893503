<template>
	<aside class="cards" v-if="store.state.user.role === 'admin'">
		<ul>
			<li>
				<a-card>
					<a-statistic title="账户数量" :value="overview.countAccount" />
				</a-card>
			</li>
			<li>
				<a-card>
					<a-statistic title="账户节点总数" :value="overview.countNodeGame" />
				</a-card>
			</li>
			<li>
				<a-card>
					<a-statistic
						title="七日内到期账户"
						:value="overview.countCloseExpireAccount"
					></a-statistic>
				</a-card>
			</li>
			<li>
				<a-card>
					<a-statistic
						title="节点数量"
						:value="overview.countNode"
					></a-statistic>
				</a-card>
			</li>
		</ul>
		<ul>
			<li>
				<a-card>
					<a-statistic
						title="家庭账户数量"
						:value="overview.countHomeAccount"
					/>
				</a-card>
			</li>
			<li>
				<a-card>
					<a-statistic
						title="家庭账户节点总数"
						:value="overview.countHomeNodeGame"
					/>
				</a-card>
			</li>
			<li>
				<a-card>
					<a-statistic
						title="七日内到期账户"
						:value="overview.countCloseHomeExpireAccount"
					></a-statistic>
				</a-card>
			</li>
			<li>
				<a-card>
					<a-statistic
						title="家庭节点数量"
						:value="overview.countHomeNode"
					></a-statistic>
				</a-card>
			</li>
		</ul>
		<ul>
			<li>
				<a-card>
					<a-statistic
						title="定制账户数量"
						:value="customData?.customAccountCount"
					/>
				</a-card>
			</li>
			<li>
				<a-card>
					<a-statistic
						title="定制账户节点总数"
						:value="customData?.customLinkCount"
					/>
				</a-card>
			</li>
			<li>
				<a-card>
					<a-statistic
						title="七日内到期账户"
						:value="customData?.customAccountNearExpireCount"
					></a-statistic>
				</a-card>
			</li>
			<li>
				<a-card>
					<a-statistic
						title="家庭节点数量"
						:value="customData?.customLinkNearExpireCount"
					></a-statistic>
				</a-card>
			</li>
		</ul>
	</aside>
	<aside class="cards" v-else>
		<a-row :gutter="16">
			<a-col span="12">
				<a-card>
					<a-statistic title="我的账户" :value="overview.countMyAccount" />
				</a-card>
			</a-col>
			<a-col span="12">
				<a-card>
					<a-statistic
						title="7天内到期账户"
						:value="overview.countMyCloseExpireAccount"
					/>
				</a-card>
			</a-col>
		</a-row>
	</aside>
</template>

<script>
import { onMounted, reactive, ref } from "vue";
import { status } from "@/utils/api";
import { useStore } from "vuex";
import service from "@/utils/axiosConfig";

export default {
	name: "TheCards",
	setup() {
		const store = useStore();
		const overview = reactive({
			countAccount: 0,
			countHomeAccount: 0,
			countNode: 0,
			countHomeNode: 0,
			countNodeGame: 0,
			countHomeNodeGame: 0,
			countCloseExpireNodeGame: 0,
			countCloseExpireAccount: 0,
			countCloseExpireHomeAccount: 0,
			countMyAccount: 0,
			countMyCloseExpireAccount: 0,
		});
		async function getOverview() {
			await status.overview().then(({ data }) => {
				if (store.state.user.role === "admin") {
					overview.countAccount = data.countAccount;
					overview.countNode = data.countNode;
					overview.countNodeGame = data.countNodeGame;
					overview.countCloseExpireNodeGame = data.countCloseExpireNodeGame;
					overview.countCloseExpireAccount = data.countCloseExpireAccount;
					overview.countHomeAccount = data.countHomeAccount;
					overview.countHomeNode = data.countHomeNode;
					overview.countHomeNodeGame = data.countHomeNodeGame;
					overview.countCloseExpireHomeAccount =
						data.countCloseExpireHomeAccount;
				} else {
					overview.countMyAccount = data.countMyAccount;
					overview.countMyCloseExpireAccount = data.countCloseExpireAccount;
				}
			});
		}

		// 获取定制账户数据
		const customData = ref(null);
		async function getCustomData() {
			const { data } = await service.get("watcher/custom/count");
			customData.value = data;
		}
		onMounted(() => {
			getOverview();
			getCustomData();
		});
		return {
			overview,
			store,
			customData,
		};
	},
};
</script>

<style scoped>
.cards {
	margin: 20px;
}
.cards ul {
	margin: 0;
	padding: 0;
	display: flex;
}
.cards ul li {
	list-style: none;
	width: 25%;
	margin-left: -1px;
}
@media only screen and (max-width: 768px) {
	.cards ul {
		display: block;
	}
	.cards ul li {
		margin-top: 20px;
		width: auto;
	}
}
</style>
