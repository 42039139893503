import service from "@/utils/axiosConfig.js";
import serviceJson from "@/utils/axiosJson";

export const box = {
	// 获得网关列表
	gateway() {
		return service.get(`box/gateway`);
	},
	config(mac) {
		return service.get(`box/gate_config?mac=${mac}`);
	},
	node(mac) {
		return service.get(`box/gate_node?mac=${mac}`);
	},
	cityList() {
		return service.get(`box/city_list`);
	},
	updateCity() {
		return service.get(`custom/city/update`);
	},
	nodeByCity(id) {
		return service.get(`box/node_by_city?id=${id}`);
	},
	create(createInfo) {
		return service.post(`box/create`, createInfo);
	},
	reset(ids) {
		return service.post(`box/node/reset`, { ids });
	},
};
// base
export const node = {
	// 获得节点信息
	getNode(id) {
		return service.post(`node`, {
			id,
		});
	},
	adminRefreshLink(id) {
		return service.post(`custom/update/admin`, {
			link_id: id,
		});
	},
	// 重启节点
	restart(id) {
		return service.get(`node/restart?id=${id}`);
	},
	// 重启定制连接
	restartLink(id) {
		return service.get(`box/link/restart?id=${id}`);
	},
	getNodeStatus() {
		return service.get(`status/nodes`);
	},

	getCustomLinks(page = 1) {
		return service.get(`custom/list?page=${page}`);
	},

	searchCustomLinks(keyword) {
		return service.get(`custom/search?keyword=${keyword}`);
	},

	getCities() {
		return service.get(`custom/city`);
	},

	initNodeStatus() {
		return service.get(`status/check`);
	},
	createNodeGroup(name) {
		return service.post(`node/create_node_group`, { name });
	},
	getNodeList(groupId) {
		return service.post(`node/list`, {
			group_id: groupId,
		});
	},
	getNodeGroupList() {
		return service.post(`node/group_list`);
	},
	// 添加节点方法
	createNode(ip, controlIp, controlPort, controlId, groupId) {
		return service.post(`node/create_node`, {
			ip: ip,
			control_ip: controlIp,
			control_port: controlPort,
			control_id: controlId,
			group_id: groupId,
		});
	},
	// 节点上添加用户
	createNodeUser(nodeId, username, password, type) {
		return service.post(`node/create_node_user`, {
			node_id: nodeId,
			username,
			password,
			type,
		});
	},
	// 节点上删除用户
	delNodeUser(nodeId, username) {
		return service.post(`node/del_node_user`, {
			node_id: nodeId,
			username,
		});
	},
	// 加载节点配置文件
	loadNodeGroupConfig(groupId) {
		return service.post(`node/load_node_group_config`, {
			group_id: groupId,
		});
	},
};

export const game = {
	createGame(name) {
		return service.post(`game/add`, {
			name,
		});
	},
	getGameList(initial) {
		return service.post(`game/list`, {
			initial,
		});
	},
	searchGame(keyword) {
		return service.post(`game/search`, {
			keyword: keyword,
		});
	},
	editGame(id, name) {
		return service.post(`v2/game/edit`, {
			id,
			name,
		});
	},
};

export const account = {
	editAccountFrequency(id, frequency) {
		return service.post(`v2/account/update_frequency`, {
			id,
			frequency,
		});
	},
	getAccountFrequency(id) {
		return service.get(`v2/account/frequency?id=${id}`);
	},
	editAccountLimit(id, bps) {
		return service.post(`account/edit_user_bps`, {
			id,
			bps,
		});
	},
	checkUsername(username) {
		return service.post(`account/check_username`, { username });
	},
	createAccount(accountInfo) {
		return serviceJson.post(`account/create`, {
			accountInfo,
		});
	},
	// 获得游戏ID对应的可用的节点数量
	getCanUseNodesByGameId(game_id) {
		return service.post(`account/search_can_use_nodes`, {
			game_id,
		});
	},
	// 获得游戏ID对应的可用家庭节点数量
	getCanUseFamilyNodesByGameId(game_id) {
		return service.get(`box/nodes_by_game?game_id=${game_id}`);
	},
	// 获得账户列表
	getAccountList(type, userId) {
		return service.post(`account/list`, {
			type,
			userId,
		});
	},
	getAccountListV2(page, limit, type, state) {
		// if type is undefined, remove limit query
		// if state is undefined, remove state query
		return service.get(
			`v2/account/admin?page=${page}&limit=${limit}${
				type ? `&type=${type}` : ""
			}${state ? `&state=${state}` : ""}`
		);
	},

	searchAccount(keyword) {
		return service.get(`v2/account/search?keyword=${keyword}`);
	},

	deleteAccount(id) {
		return service.post(`account/delete`, {
			id: id,
		});
	},
	deleteCustomAccount(id) {
		return service.post(`custom/del`, {
			id: id,
		});
	},
	getAccountExport(id) {
		return service.get(`v2/account/info?id=${id}`);
	},
	getAccountNodesList(id) {
		return service.post(`account/get_account_nodes_list`, { id });
	},
	renewAccountNode(id, month, type, nodes) {
		return service.post(`account/renew_account`, {
			id,
			month,
			type,
			nodes,
		});
	},
	// 从账户里删除节点
	deleteNodesFromAccount(account_id, nodes) {
		return service.post(`account/delete_nodes_from_account`, {
			account_id,
			nodes,
		});
	},
	// 向账户里添加节点
	addNodesIntoAccount(account_id, orders) {
		return serviceJson.post(`account/add_nodes_into_account`, {
			account_id,
			orders,
		});
	},
	// 转移账户
	changeAccountUser(account_id, to_user_id) {
		return service.post(`account/change_account_user`, {
			account_id,
			to_user_id,
		});
	},
	blockAccount(id, action) {
		return service.post(`account/block_account`, {
			account_id: id,
			action: action,
		});
	},
	// 修改账户最大更新次数
	editAccountMaxUpdateTimes(id, count) {
		return service.post(`custom/update/max/count`, {
			id,
			count,
		});
	},
};
// 权限相关方法
export const auth = {
	login(username, password, hash) {
		return service.post(`login`, {
			username,
			password,
			hash,
		});
	},
};

export const user = {
	setPrice({ id, datacenter, home, custom }) {
		return service.post("user/price/set_all", {
			userId: id,
			datacenter,
			home,
			custom,
		});
	},
	listV2(page, limit, state) {
		return service.get(
			`v2/user/list?page=${page}&limit=${limit}&state=${state}`
		);
	},
	search(keyword) {
		return service.get(`user/search?keyword=${keyword}`);
	},
	blockRecharge(id) {
		return service.post(`user/block_recharge`, {
			id,
		});
	},
	unBlockRecharge(id) {
		return service.post(`user/unblock_recharge`, {
			id,
		});
	},
	list(type) {
		return service.post(`user/list`, {
			type,
		});
	},
	changeState(id, state) {
		return service.post(`user/change_user_state`, {
			user_id: id,
			state: state,
		});
	},
	createAgency(username, password) {
		return service.post(`agency/create`, {
			username,
			password,
		});
	},
	rechargeAgency(user_id, coin) {
		return service.post(`agency/recharge`, {
			user_id,
			coin,
		});
	},
	transfer(toUserId, coin) {
		return service.post(`wallet/transfer`, {
			toUserId,
			coin,
		});
	},
	changePassword(userId, password) {
		return service.post(`user/change_user_password`, {
			userId,
			password,
		});
	},
	addRealName(id, realName, idCard) {
		return service.post(`user/real_name_admin`, {
			id,
			name: realName,
			card: idCard,
		});
	},
};

export const bill = {
	list(startTime, endTime) {
		return service.post(`bill/list`, {
			startTime,
			endTime,
		});
	},
	// 管理员账单 分页 时间段 类型
	listAdmin(page, limit, type, start, end, username) {
		return service.get(
			`bill/admin?page=${page}&limit=${limit}${start ? `&start=${start}` : ""}${
				end ? `&end=${end}` : ""
			}${type ? `&type=${type}` : ""}${
				username !== "" ? `&username=${username}` : ""
			}`
		);
	},
};

export const log = {
	hostList() {
		return service.post(`node/get_host_list`, {});
	},
	searchLog(host, startTime, endTime) {
		return service.post(`log/search`, {
			host,
			startTime,
			endTime,
		});
	},
	searchLogByHostAndZid(host, zid, startTime, endTime) {
		return service.post(`log/search_log_host_zid`, {
			host,
			zid,
			startTime,
			endTime,
		});
	},
};

export const status = {
	overview() {
		return service.get(`status/overview`);
	},
	test(host, protocol, username, password) {
		return service.post(`status/test/`, {
			host,
			protocol,
			username,
			password,
		});
	},
	checkProxyLink(protocol, ip, username, password, port) {
		return service.post(`status/check_proxy`, {
			protocol,
			ip,
			username,
			password,
			port,
		});
	},
	checkProxyLinkByAccount(id) {
		return service.post(`status/check_proxy_by_account`, { id });
	},
};
